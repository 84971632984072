<template>
  <div>
    <v-app>
      <div class="row">
        <div class="col-xl-12">
          <KTPortlet v-bind:title="'Coupon Codes'">
            <template v-slot:toolbar>
              <div class="col-md-12 pull-right text-right">
                <v-btn
                  @click="showDialog"
                  text
                  class="btn btn-clean btn-sm btn-transparent"
                  style="background:transparent;"
                >
                  <i class="flaticon-add-circular-button"></i> Add Catlogs
                </v-btn>

                <v-btn text :to="{ name: 'manage-websites', params: { domainname: siteUrl }}">
                  <i class="fas fa-arrow-circle-left"></i> Back
                </v-btn>
              </div>
            </template>
            <template v-slot:body>
              <b-table
                hover
                :items="catlogs"
                id="my-table"
                :busy.sync="isBusy"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
              >
                <template v-slot:table-busy>

                  <div class="text-center text-info my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading Pages...</strong>
                  </div>
                </template>
                <template v-slot:cell(is_parent)="data">
                  <i
                    v-if="data.item.is_parent"
                    class="kt-nav__link-icon flaticon2-check-mark text-success"
                  ></i>
                  <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
                </template>
                <template v-slot:cell(is_active)="data">
                  <i
                    v-if="data.item.is_active"
                    class="kt-nav__link-icon flaticon2-check-mark text-success"
                  ></i>
                  <i v-else class="kt-nav__link-icon flaticon2-cancel-music text-danger"></i>
                </template>

                <!-- A virtual composite column -->
                <template v-slot:cell(action)="data">
                  <div class="kt-widget__toolbar">
                    <a
                      href="#"
                      class="btn btn-clean btn-sm btn-icon btn-icon-md"
                      data-toggle="dropdown"
                    >
                      <i class="flaticon-more-1"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right">
                      <ul class="kt-nav">
                        <li class="kt-nav__item">
                          <router-link
                            :to="{ name: 'product-detail', params: { productid: data.item.id }}"
                            class="kt-nav__link"
                          >
                            <i class="kt-nav__link-icon flaticon-edit"></i>
                            <span class="kt-nav__link-text">Edit</span>
                          </router-link>
                        </li>

                        <li class="kt-nav__item">
                          <a href="#" class="kt-nav__link" @click="delele(data.item.id)">
                            <i class="kt-nav__link-icon flaticon-delete kt-font-danger"></i>
                            <span class="kt-nav__link-text">Delete</span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </template>
              </b-table>
              <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                aria-controls="my-table"
              ></b-pagination>
            </template>
          </KTPortlet>
        </div>
      </div>
      <v-dialog scrollable v-model="catlogDialog" persistent max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Add New Catlogs</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <div class="form-block">
                <v-row>
                  <v-col cols="8" md="8">
                    <v-text-field label="Title" v-model="catlog.title" required></v-text-field>
                    <p
                      class="text-danger font-weight-bold"
                      v-if="$v.catlog.title.$error"
                    >Title is required</p>
                  </v-col>
                  <v-col cols="2" md="2">
                    <v-switch v-model="catlog.is_active" label="Status"></v-switch>
                  </v-col>
                  <v-col cols="2" md="2">
                    <v-switch v-model="catlog.is_parent" label="Parent"></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="!catlog.is_parent">
                  <v-col cols="8" md="8">
                    <v-select
                      v-model="catlog.parent_id"
                      :items="catlogs"
                      item-text="title"
                      item-value="id"
                      label="category"
                    ></v-select>
                  </v-col>
                </v-row>
              </div>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDialog">Close</v-btn>
            <v-btn color="blue darken-1" text @click.prevent="savePage">Save</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-app>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { required } from "vuelidate/lib/validators";
import ProductCatalogRootService from "@/services/store/ProductCatalogRootService";
const ProductRoot = new ProductCatalogRootService();
import ProductCatalogService from "@/services/store/ProductCatalogService";
const ProductCatalog = new ProductCatalogService();
export default {
  name: "store-coupon-codes",
  components: {
    KTPortlet
  },
  validations: {
    catlog: {
      title: { required },
      custom_slug: { required }
    }
  },
  data() {
    return {
      isBusy: false,
      catlogDialog: false,
      sortBy: "title",
      sortDesc: false,
      url: null,
      fields: [
        { key: "title", sortable: true },
        { key: "is_parent", label: "Is Parent", sortable: true },
        { key: "is_active", label: "status", sortable: false },
        { key: "action", sortable: false }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [5, 10, 15],

      catlog: {
        title: "",
        is_parent: "",
        parent_id: "",
        is_active: "",
        seo_description: ""
      },
      catlogs: [
        {
          id: 1,
          title: "Google Pixel",
          slug: "GP3xl",
          is_parent: 1,
          is_deleted: 0,
          is_active: 1
        },
        {
          id: 1,
          title: "Iphone 11 Pro",
          slug: "GP3xl",
          is_parent: 1,
          is_deleted: 0,
          is_active: 1
        },
        {
          id: 1,
          title: "Samsung 11",
          slug: "GP3xl",
          is_parent: 1,
          is_deleted: 0,
          is_active: 1
        }
      ]
    };
  },
  methods: {
    // showDialog() {
    //   this.catlogDialog = true;
    // },
    // closeDialog() {
    //   this.catlogDialog = false;
    // },

    // delele(id) {
    //   this.$root
    //     .$confirm("Delete", "Confirm delete coupon code? ", {
    //       color: "info"
    //     })
    //     .then(confirm => {
    //       if (confirm) {
    //         WebsitePageService.delete(this.siteUrl, id).then(res => {
    //           this.$snotify.success("Page Deleted Sucessfully");
    //           this.getPages();
    //         });
    //       }
    //     });
    // },
    // savePage() {
    //   this.$v.$touch();
    //   if (!this.$v.page.$error) {
    //     WebsitePageService.create(this.siteUrl, this.page).then(res => {
    //
    //       this.pageDialog = false;
    //       this.$snotify.success("Page added");
    //       this.getPages();
    //     });
    //   }
    // },
    // toggleBusy() {
    //   this.isBusy = !this.isBusy;
    // },
    // imageChange(e) {
    //   this.url = URL.createObjectURL(e);
    //   console.log(e);
    // },
    getPages() {
      alert('asd')
      ProductRoot.paginate().then(res => {

        // this.pages = res.data.data;
      });
    },
    // editPage(id) {
    //   window.location =
    //     process.env.VUE_APP_SERVER_URL +
    //     "pagebuilder/" +
    //     this.siteUrl +
    //     "/website/editor/" +
    //     id +
    //     "/update";
    //   console.log(id);
    // }
  },

  computed: {
    rows() {
      return this.catlogs.length;
    },
    siteUrl() {
      return this.$route.params.domainname;
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Manage Products", route: "/websites" }
    ]);
    this.getPages();
  }
};
</script>
<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 350px;
}
</style>
